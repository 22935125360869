import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { LoaderComponent } from './components/loader/loader.component';
import { RequestInterceptor } from '../Interceptors/request.interceptor';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        LoaderComponent
    ],
    exports: [
        HttpClientModule,
        LoaderComponent,
    ],
    providers: [
        HttpService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        }
    ]
})
export class SharedModule { }
