import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { FEEDBACK_MANAGER_DETAIL } from '../constant/routes';
import { NO_INTERNET, TIMEOUT } from '../constant/messages';
import { LoaderService } from '../shared/services/loader.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private loaderService: LoaderService,
    ) {
           
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let  token = this.router.url.split('manage-feedback/')[1]
        token = token.split('/')[0];
        
        const headers = {};
        headers['authorization'] = `Basic ${btoa(environment.basic)}`
        if (token) {
            headers['access_token'] = `bearer ${token}`
        }
        request = request.clone({
            setHeaders: headers
        });

        return next.handle(request).pipe(
            timeout(30000),
            tap(
                (data) => {
                    if (data instanceof HttpResponse) {
                        this.loaderService.hideLoader();
                    }
                    
                },
                (err: any) => {
                    this.loaderService.hideLoader();
                    if (err.name.toLowerCase().includes('timeout')) {
                        alert(TIMEOUT)
                    }

                    if (!navigator.onLine) {
                        alert(NO_INTERNET)
                        return
                    }
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 403) {
                            this.router.navigate(['/']);
                        }

                        if (err.status === 401 || err.status === 423 || err.status === 440 || err.error.responseType === 'UNAUTHORIZED') {
                            this.router.navigate([FEEDBACK_MANAGER_DETAIL.fullUrl, token]);
                        }
                    }
                }
            ));
    }
}
