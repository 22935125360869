import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FEEDBACK_MANAGER, REQUEST_CANCEL, REQUEST_COMPLETE } from './constant/routes';
import { PageNotFoundComponent } from './modules/layout/layout-shared/page-not-found/page-not-found.component';
import { RequestCancelComponent } from './modules/layout/layout-shared/request-cancel/request-cancel.component';
import { RequestCompleteComponent } from './modules/layout/layout-shared/request-complete/request-complete.component';
import { LayoutComponent } from './modules/layout/view/layout.component';



const appRoutes: Routes = [
  { path: '', redirectTo: FEEDBACK_MANAGER.path, pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./modules/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: REQUEST_CANCEL.path, component: RequestCancelComponent },
  { path: REQUEST_COMPLETE.path, component: RequestCompleteComponent },
  // { path: "**", component: LayoutComponent }
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
