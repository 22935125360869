import { IRoute } from '../models/common-models';

export const FEEDBACK_MANAGER: IRoute = {
  path: 'feedback',
  get fullUrl(): string {
    return `/${this.path}`;
  }
};

export const FEEDBACK_MANAGER_DETAIL: IRoute = {
  path: 'manage-feedback',
  get fullUrl(): string {
    return `${FEEDBACK_MANAGER.fullUrl}/${this.path}`;
  }
};

export const REQUEST_CANCEL: IRoute = {
  path: 'request-cancel',
  get fullUrl(): string {
    return `/${this.path}`;
  }
};

export const REQUEST_COMPLETE: IRoute = {
  path: 'request-complete',
  get fullUrl(): string {
    return `/${this.path}`;
  }
};

